import "./App.css";
import { Routes, Route, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import DocopaDrawer from "./components/DocopaDrawer.js";
import Top from "./views/Top.js";
import Playmovie from "./views/Playmovie.js";
import Playlist from "./views/Playlist.js";
import TagVideoList from "./views/TagVideoList.js";
import Manage from "./views/Manage.js";
import ManageTest from "./views/Management.js";
import Edit from "./views/Edit.js";
import Register from "./views/Register.js";
import Version from "./views/Version.js";
import NotFound from "./views/NotFound.js";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useState, useEffect } from "react";
import Mypage from "./views/Mypage.js";
import { getInformation } from "./graphql/queries";

import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import { I18n } from "aws-amplify";
import { translations } from "@aws-amplify/ui-react";
import TagManager from "react-gtm-module";

import SecureRoute from "./components/SecureRoute"; // SecureRouteコンポーネントをインポート
import { Security, LoginCallback } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";

import { API } from "@aws-amplify/api";
import * as mutations from "./graphql/mutations";

Amplify.configure({
  Auth: {
    region: "ap-northeast-1",
    userPoolId: window._env_.REACT_APP_COGNITO_USERPOOL_ID,
    userPoolWebClientId: window._env_.REACT_APP_COGNITO_USERPOOL_WEBCLIENT_ID,
  },

  aws_appsync_graphqlEndpoint: window._env_.APPSYNC_GRAPHQLENDPOINT,
  aws_appsync_region: "ap-northeast-1",
  // Set the auth type as "API_KEY" and pass in the API key value
  aws_appsync_authenticationType: "API_KEY",
  aws_appsync_apiKey: window._env_.APPSYNC_APIKEY,
});

// 日本語設定
I18n.putVocabularies(translations);
I18n.setLanguage("ja");

/** ログイン画面のヘッダー */
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

TagManager.initialize({ gtmId: window._env_.GTM_ID });

const oktaAuth = new OktaAuth({
  issuer: window._env_.OKTA_ISSUER,
  clientId: window._env_.OKTA_CLIENT_ID,
  redirectUri: window.location.origin + "/login/callback",
  tokenManager: {
    storage: "sessionStorage", // セッションストレージに認証トークン情報を格納
  },
});

function App() {
  const location = useLocation();
  const isManagePath = location.pathname.startsWith("/manage");
  const theme = createTheme({
    components: {
      MuiCssBaseline: {
        styleOverrides: `
            ::-webkit-scrollbar {
            width: 18px;
            },
            ::-webkit-scrollbar-thumb {
                background-color: gray;
                border-radius: 12px;
                border-top: 4px solid transparent;
                border-bottom: 4px solid transparent;
                border-right: 4px solid transparent;
                border-left: 4px solid transparent;
                background-clip: padding-box;
            }
            `,
      },
    },
  });

  // メンテナンス確認
  const [isMaintenace, setIsMaintenace] = useState(false);
  const isMaintenacePath = location.pathname === "/";
  const [maintenaceMeg, setMaintenaceMeg] = useState(null);
  const [loding, setLoding] = useState(true);
  // ログインユーザーの権限確認
  const [userIsAdmin, setUserIsAdmin] = useState([]);
  // ログインユーザー確認
  // const [userInfo, setUserInfo] = useState();
  const [oktaAuthenticated, setOktaAuthenticated] = useState(false); //Okta認証が済んでいるかどうか
  const [userEmail, setUserEmail] = useState(""); //ユーザーのemail
  const [getUserApiResult, setGetUserApiResult] = useState(null); // userテーブルと突合した情報(nullの場合未突合)

  // Okta認証
  oktaAuth.authStateManager.subscribe((authState) => {
    //Okta認証済の場合ユーザーのメールアドレスを設定
    if (authState.isAuthenticated) {
      oktaAuth.token.getUserInfo().then((e) => {
        setUserEmail(e.email);
      });
    }
    //現在のOkta認証状況を設定
    setOktaAuthenticated(authState.isAuthenticated);
  });

  // docopaユーザ認証
  useEffect(() => {
    async function registerUser() {
      //Okta認証済かつメールアドレス設定済の場合、Docopaユーザー突合処理
      if (oktaAuthenticated && userEmail !== "") {
        try {
          const result = await API.graphql({
            query: mutations.registerUser,
            variables: {
              email: userEmail,
            },
          });
          setGetUserApiResult(result.data.registerUser.userData); // ユーザ情報を保存
        } catch (error) {
          console.error("Error:", error);
        }
      }
    }

    //上記関数を実行
    registerUser();
    // eslint-disable-next-line
  }, [oktaAuthenticated, userEmail]);

  useEffect(() => {
    const fetchUserIsAdmin = async () => {
      try {
        if (getUserApiResult && getUserApiResult.is_admin !== null) {
          setUserIsAdmin(getUserApiResult.is_admin);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchUserIsAdmin();
  }, [getUserApiResult]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const informationResponse = await API.graphql({
          query: getInformation,
          variables: {
            id: 1,
          },
        });
        if (informationResponse.data.getInformation.data.is_use) {
          setIsMaintenace(true);
          setMaintenaceMeg(
            informationResponse.data.getInformation.data.description,
          );
        }

        setLoding(false);
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchData();
  }, []);

  /** メンテナンス画面内容 */
  function MaintenaceScreen() {
    return (
      <div>
        <div dangerouslySetInnerHTML={{ __html: maintenaceMeg }} />
      </div>
    );
  }

  /* SecureRouteにgetUserApiResultを一括で渡す関数 */
  function HomeContent(component) {
    return (
      <SecureRoute component={component} getUserApiResult={getUserApiResult} />
    );
  }

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    window.location.replace(
      toRelativeUrl(originalUri || "/", window.location.origin),
    );
  };

  if (loding) return <div></div>; // メンテナンス画面読み込み中
  return (
    <div>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <ThemeProvider theme={theme}>
          <Box sx={{ display: "flex" }}>
            <CssBaseline />
            {isManagePath ||
            (isMaintenacePath && isMaintenace) ||
            !getUserApiResult ? null : (
              <DocopaDrawer getUserApiResult={getUserApiResult} />
            )}
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
              {!getUserApiResult ? null : <DrawerHeader />}
              <Routes>
                <Route path="/login/callback" element={<LoginCallback />} />
                {!getUserApiResult &&
                  location.pathname !== "/login/callback" && (
                    <Route
                      path="/*"
                      element={HomeContent(() => (
                        <div></div>
                      ))}
                    />
                  )}
                {getUserApiResult && (
                  <>
                    <Route
                      path="/"
                      element={
                        isMaintenace
                          ? HomeContent(MaintenaceScreen)
                          : HomeContent(Top)
                      }
                    />
                    <Route path="/top" element={HomeContent(Top)} />
                    <Route
                      path="/playmovie/:id"
                      element={HomeContent(Playmovie)}
                    />
                    <Route
                      path="/playlist/:id"
                      element={HomeContent(Playlist)}
                    />
                    <Route
                      path="/tagvideolist"
                      element={HomeContent(TagVideoList)}
                    />
                    <Route
                      path="/managetest"
                      element={
                        userIsAdmin
                          ? HomeContent(ManageTest)
                          : HomeContent(NotFound)
                      }
                    />
                    <Route
                      path="/manage"
                      element={
                        userIsAdmin
                          ? HomeContent(Manage)
                          : HomeContent(NotFound)
                      }
                    />
                    <Route
                      path="/manage/register"
                      element={
                        userIsAdmin
                          ? HomeContent(Register)
                          : HomeContent(NotFound)
                      }
                    />
                    <Route
                      path="/manage/edit"
                      element={
                        userIsAdmin ? HomeContent(Edit) : HomeContent(NotFound)
                      }
                    />
                    <Route path="/version" element={HomeContent(Version)} />
                    <Route path="/mypage" element={HomeContent(Mypage)} />
                    <Route path="/*" element={HomeContent(NotFound)} />
                  </>
                )}
              </Routes>
            </Box>
          </Box>
        </ThemeProvider>
      </Security>
    </div>
  );
}

export default App;
